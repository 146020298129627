<template>
  <!-- 用户管理 -->
  <div class="alarm">
    <div class="usertop">
      <p>报警管理</p>
    </div>
    <div class="usermain">
      <div class="userbtn">
        <p>报警设备：</p>
        <el-input
          v-model="shebei"
          placeholder="请输入设备名称或编号"
          style="width: 220px"
          @keyup.enter.native="chaxun"
        ></el-input>
        <p>所属企业：</p>
        <el-input
          v-model="qiye"
          placeholder="请输入所属企业"
          style="width: 200px"
          @keyup.enter.native="chaxun"
        ></el-input>
        <p>报警内容：</p>
        <el-input
          v-model="neirong"
          placeholder="请输入"
          style="width: 300px"
          @keyup.enter.native="chaxun"
        ></el-input>
        <el-button type="primary" style="margin-left: 15px" @click="chaxun"
          >查询</el-button
        >
      </div>
      <div class="userlist">
        <el-table
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%"
          @selection-change="handleSelectionChange"
        >
          <el-table-column label="报警设备" min-width="80%">
            <template slot-scope="scope">
              <p>{{ scope.row.device }}</p>
              <!-- <p>{{ scope.row.enterprise_number }}</p> -->
            </template>
          </el-table-column>
          <el-table-column label="设备运行" min-width="50%">
            <template slot-scope="scope">
              <p
                @click="toview(scope.$index, scope.row)"
                style="color: #5c98f7; cursor: pointer"
              >
                查看详情
              </p>
            </template>
          </el-table-column>
          <el-table-column
            prop="content_error"
            label="报警内容"
            min-width="70%"
          >
          </el-table-column>
          <el-table-column label="所属企业" show-overflow-tooltip>
            <template slot-scope="scope">
              <p>{{ scope.row.enterprise }}</p>
              <p>{{ scope.row.enterprise_number }}</p>
            </template>
          </el-table-column>
          <el-table-column label="联系人1" show-overflow-tooltip>
            <template slot-scope="scope">
              <p>{{ scope.row.user_name }}</p>
              <p>{{ scope.row.phone }}</p>
            </template>
          </el-table-column>
          <el-table-column label="联系人2" show-overflow-tooltip>
            <template slot-scope="scope">
              <p>{{ scope.row.user_names }}</p>
              <p>{{ scope.row.phones }}</p>
            </template>
          </el-table-column>
          <el-table-column prop="created_at" label="报警时间" min-width="130%">
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <div class="userpagging">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage1"
            :page-sizes="[10, 15, 20]"
            :page-size="10"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { errorlist } from "../../api/alarm/list.js";
export default {
  components: {},
  props: {},
  data() {
    return {
      dialogVisible: false,
      shebei: "",
      qiye: "",
      neirong: "",
      tableData: [],
      // 这个是控制选择框的参数
      multipleSelection: [],
      currentPage1: 1,
      currentPage2: 2,
      currentPage3: 3,
      currentPage4: 4,

      total: 0,
      // 查询
      newshe: "",
      newqi: "",
      newcon: "",
      page: 1,
      pagesize: 10,
    };
  },
  created() {
    console.log("123456");
    errorlist("", "", "", 1, 10).then((res) => {
      console.log(res);
      this.tableData = res.data.data;
      this.total = res.data.total;
    });
  },
  mounted() {},
  methods: {
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.currentPage1 = 1;
      this.pagesize = val;
      this.findlist(
        this.newshe,
        this.newqi,
        this.newcon,
        this.currentPage1,
        this.pagesize
      );
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage1 = val;
      this.findlist(
        this.newshe,
        this.newqi,
        this.newcon,
        this.currentPage1,
        this.pagesize
      );
    },
    // 查询
    chaxun() {
      this.newshe = JSON.parse(JSON.stringify(this.shebei));
      this.newqi = JSON.parse(JSON.stringify(this.qiye));
      this.newcon = JSON.parse(JSON.stringify(this.neirong));
      this.findlist(this.shebei, this.qiye, this.neirong, 1, this.pagesize);
      this.currentPage1 = 1;
    },
    // 查看数据列表
    findlist(shebei, qiye, neirong, page, pagesize) {
      errorlist(shebei, qiye, neirong, page, pagesize).then((res) => {
        this.tableData = res.data.data;
        this.total = res.data.total;
      });
    },
    // 查看详情
    toview(index, row) {
      console.log(index);
      console.log(row);
      console.log("查看详情");
      console.log(index);
      console.log(row);
      this.$router.push({
        path: "/detail",
        query: { detailmessage: JSON.stringify(row), role: 1 },
      });
    },
  },
};
</script>

<style  lang='less' scoped>
.alarm {
  padding: 20px;
  .usertop {
    height: 67px;
    background: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    font-size: 24px;
    margin-bottom: 20px;
  }
  .usermain {
    min-height: 760px;
    background: white;
    padding: 35px;
    padding-bottom: 15px;
    // p {
    //   margin: 0px;
    // }
    .userbtn {
      margin-bottom: 20px;
      /deep/.el-button--success {
        font-size: 24px;
      }

      /deep/.el-button--danger {
        font-size: 24px;
      }
      /deep/.el-input__inner {
        font-size: 18px;
      }
      /deep/.el-select-dropdown__item {
        font-size: 20px;
      }
      /deep/.el-button--primary {
        font-size: 20px;
      }
      display: flex;
      align-items: center;
      p {
        margin-left: 15px;
        font-size: 24px;
      }
    }
    .userlist {
      .selbtn {
        display: flex;
        p {
          margin-right: 10px;
          color: #5c89f4;
          cursor: pointer;
        }
      }
    }
    .userpagging {
      margin-top: 20px;
    }
  }
  /deep/.el-table th,
  .el-table tr {
    font-size: 20px !important;
    height: 70px;
    background: #f2f2f2;
  }
  /deep/.el-table__body,
  .el-table__footer,
  .el-table__header {
    font-size: 20px !important;
  }
  /deep/.el-switch__label * {
    font-size: 16px;
  }
  /deep/.el-pagination__total {
    font-size: 18px !important;
  }
  /deep/.el-pager li {
    font-size: 17px;
  }
  /deep/.el-pagination__jump {
    font-size: 20px !important;
  }
  /deep/.el-input__inner {
    font-size: 17px;
  }
  /deep/.el-input--suffix .el-input__inner {
    font-size: 18px !important;
  }
  /deep/.el-dialog__title {
    font-size: 24px;
  }
  /deep/.el-dialog__body {
    font-size: 20px !important;
  }
}
</style>
